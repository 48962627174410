import { GatsbyImage } from "gatsby-plugin-image";
import { ReactElement } from "react";
import { className } from "../../../shared/ClassName";
import CtaLink from "../../Shared/CtaLink";
import * as styles from "./ImageWithText.module.scss";

export default function ImageWithText(props: GatsbyTypes.strapi_ComponentWidgetsImageWithText): ReactElement {
    const base = props.base ?? { heading: "", paragraph: "" };
    const imageData = props?.image?.url_sharp?.childImageSharp?.gatsbyImageData;
    const isRightDirection = props?.orientation === "right";

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div {...className(styles.imageWithText, isRightDirection ? styles.right : styles.left)}>
                    {imageData && <GatsbyImage image={imageData} alt={base.heading ?? ""} />}
                    <div className={styles.text}>
                        <h4>{base.heading}</h4>
                        <div dangerouslySetInnerHTML={{ __html: base.paragraph ?? "" }}></div>
                        {props.cta && <CtaLink cta={props.cta} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
