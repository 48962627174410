import { ReactElement } from "react";
import { ContextCollection } from "../../contexts/DependencyContext";
import Posts from "../../components/Widgets/BlogPosts";
import Hero from "../../components/Widgets/Hero";
import ImageWithText from "../../components/Widgets/ImageWithText";
import NoWidget from "../../components/Widgets/NoWidget";
import Products from "../../components/Widgets/Products";
import SpecialOffer from "../../components/Widgets/SpecialOffer";
import Support from "../../components/Widgets/Support";
import { IGenericWidget } from "./IGenericWidget";

export type WidgetProps = IGenericWidget & GatsbyTypes.strapi_HomePageDynamicDynamicZone;

export default class HomeWidgetService {
    public getType(widgetProps: WidgetProps): string {
        return (widgetProps.__typename as string)?.replace("strapi_ComponentWidgets", "")?.toLowerCase();
    }

    public getKey(widgetProps: WidgetProps): string {
        return `${this.getType(widgetProps)}-${widgetProps.id}`;
    }

    public getWidget(widgetProps: WidgetProps, generalOffer: GatsbyTypes.Maybe<GatsbyTypes.strapi_GeneralSpecialOffer>): ReactElement {
        const type = this.getType(widgetProps);

        switch (type) {
            case "hero":
                return <Hero {...(widgetProps as GatsbyTypes.strapi_ComponentWidgetsHero)} />;

            case "products":
                return <Products {...(widgetProps as GatsbyTypes.strapi_ComponentWidgetsProducts)} />;

            case "blogs":
                return <Posts {...(widgetProps as GatsbyTypes.strapi_ComponentWidgetsBlogs)} />;

            case "imagewithtext":
                return <ImageWithText {...(widgetProps as GatsbyTypes.strapi_ComponentWidgetsImageWithText)} />;

            case "specialoffer":
                return <SpecialOffer widgetOffer={widgetProps as GatsbyTypes.strapi_ComponentWidgetsSpecialOffer} generalOffer={generalOffer} />;

            case "support":
                return <Support {...(widgetProps as GatsbyTypes.strapi_ComponentWidgetsSupport)} />;

            default:
                return <NoWidget {...widgetProps} />;
        }
    }
}

ContextCollection.registerTransient(HomeWidgetService);
