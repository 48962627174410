import { ReactElement } from "react";
import Product from "./Product";
import * as styles from "./Products.module.scss";

export default function Products(props: GatsbyTypes.strapi_ComponentWidgetsProducts): ReactElement {
    return (
        <div className={styles.featuredProducts}>
            <div className="mainBox">
                <div className={styles.inner}>
                    <h2>{props.base?.heading ?? ""}</h2>
                    <div className={styles.products}>
                        {props.products?.map(x => (
                            <Product key={x?.id} product={x as GatsbyTypes.strapi_Product} linkText={props.productLinkText ?? ""} linkTo="products" className={styles.singleProduct} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
