import { graphql } from "gatsby";
import { ReactElement } from "react";
import Helmet from "../components/Helmet";
import Widget from "../components/Shared/Widget";
import { useDependency } from "../contexts/DependencyContext";
import HomeWidgetService from "../services/widgets/HomeWidgetService";
import "../stylesheets/main.scss";
import * as styles from "./Index.module.scss";

interface IIndexProps {
    data: GatsbyTypes.Query;
}

export default function IndexPage({ data }: IIndexProps): ReactElement {
    const widgetService = useDependency(HomeWidgetService);
    const widgetsData: Readonly<any[]> = data?.strapi?.homePage?.dynamic ?? [];
    const generalOffer = data?.strapi?.generalSpecialOffer;
    const title = "Home";
    const description = "";

    return (
        <>
            <Helmet title={title} description={description} />
            <div className={styles.container}>
                {widgetsData
                    .filter(x => !!x)
                    .map(x => (
                        <Widget key={widgetService.getKey(x)}>{widgetService.getWidget(x, generalOffer)}</Widget>
                    ))}
            </div>
        </>
    );
}

export const query = graphql`
    query HomePage {
        strapi {
            homePage {
                dynamic {
                    ... on strapi_ComponentWidgetsHero {
                        __typename
                        id
                        base {
                            paragraph
                            heading
                        }
                        image {
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(quality: 60)
                                }
                            }
                        }
                        cta {
                            link
                            text
                            external
                        }
                    }
                    ... on strapi_ComponentWidgetsBlogs {
                        __typename
                        id
                        base {
                            heading
                        }
                        posts(limit: 2, sort: "date:desc") {
                            id
                            date
                            title
                            summary
                            post_categories(limit: 1) {
                                id
                                name
                                color
                            }
                        }
                    }
                    ... on strapi_ComponentWidgetsImageWithText {
                        __typename
                        id
                        orientation
                        base {
                            heading
                            paragraph
                        }
                        cta {
                            text
                            link
                            external
                        }
                        image {
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(quality: 60)
                                }
                            }
                        }
                    }
                    ... on strapi_ComponentWidgetsProducts {
                        __typename
                        id
                        base {
                            heading
                            paragraph
                        }
                        productLinkText
                        products(limit: 4) {
                            id
                            name
                            description
                            color
                        }
                    }
                    ... on strapi_ComponentWidgetsSpecialOffer {
                        __typename
                        id
                        useGeneral
                        base {
                            heading
                            paragraph
                        }
                        cta {
                            text
                            link
                            external
                        }
                    }
                    ... on strapi_ComponentWidgetsSupport {
                        __typename
                        id
                        base {
                            heading
                            paragraph
                        }
                        cta {
                            external
                            link
                            text
                        }
                    }
                }
            }
            generalSpecialOffer {
                base {
                    heading
                    paragraph
                }
                cta {
                    link
                    text
                    external
                }
            }
        }
    }
`;
