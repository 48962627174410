import { GatsbyImage } from "gatsby-plugin-image";
import { ReactElement } from "react";
import CtaLink from "../../Shared/CtaLink";
import * as styles from "./Hero.module.scss";
import { ddcToolboxName, homeHero } from "../../Icons";

export default function Hero(props: GatsbyTypes.strapi_ComponentWidgetsHero): ReactElement {
    const base = props?.base ?? { heading: "ddcToolbox", paragraph: "" };
    const imageData = props?.image?.url_sharp?.childImageSharp?.gatsbyImageData;

    return (
        <div>
            <div className={styles.hero}>
                <div className="mainBox">
                    <div className={styles.inner}>
                        <div className={styles.homeHero}>{homeHero}</div>
                        <div className={`mainBox ${styles.banner}`}>
                            <div className={styles.iconContainer}> {ddcToolboxName} </div>
                            <div dangerouslySetInnerHTML={{ __html: base.paragraph ?? "" }}></div>
                            {props.cta && (
                                <CtaLink cta={props.cta}>
                                    <button>{props.cta.text}</button>
                                </CtaLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
