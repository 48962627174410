import { ReactElement } from "react";
import Post from "../../Shared/Post";
import * as styles from "./BlogPosts.module.scss";

export default function BlogPosts(props: GatsbyTypes.strapi_ComponentWidgetsBlogs): ReactElement {
    return (
        <div className={styles.latestAnnouncements}>
            <div className="mainBox">
                <div className={styles.inner}>
                    <h2>{props.base?.heading}</h2>
                    <div className={styles.posts}>
                        {props.posts?.map(x => (
                            <Post key={x?.id} post={x as GatsbyTypes.strapi_Posts} linkText="Read article" className={styles.singleBlog} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
