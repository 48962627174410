import { ReactElement } from "react";
import CtaLink from "../../Shared/CtaLink";
import { className } from "../../../shared/ClassName";
import * as styles from "./Support.module.scss";

export default function Support(props: GatsbyTypes.strapi_ComponentWidgetsSupport): ReactElement {
    const base = props?.base ?? { heading: "ddcToolbox", paragraph: "" };

    return (
        <div {...className(styles.support)}>
            <div className={`mainBox ${styles.inner}`}>
                <h2>{base.heading ?? ""}</h2>
                <div dangerouslySetInnerHTML={{ __html: base.paragraph ?? "" }}></div>
                {props.cta && <CtaLink cta={props.cta} />}
            </div>
        </div>
    );
}
